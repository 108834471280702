import { Link, animateScroll as scroll } from "react-scroll";
import mainLogo from "./assets/logo_hor.png";
const Header = ({ setScreen }) => {
  return (
    <div className="section header">
      <div
        className="inner"
        data-aos="fade"
        data-aos-duration="1500"
        data-aos-delay="500"
      >
        <img style={{ width: "250px" }} src={mainLogo} alt=""></img>

        <div>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
            onClick={() => setScreen("home")}
          >
            <button>Бидний тухай</button>
          </Link>
          <Link
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
            onClick={() => setScreen("home")}
          >
            <button>Захиалга</button>
          </Link>
          <Link
            activeClass="active"
            to="verify"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
          >
            <button
              className="verify"
              onClick={() => {
                setScreen("forms");
                scroll.scrollToTop();
              }}
            >
              Бидэнтэй холбогдох
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
