
import React, { useState, useEffect } from "react";
import "./styles.css";
import "./styles.scss";
import AOS from "aos";
import {
    Link,

    animateScroll as scroll,

} from "react-scroll";
import "../node_modules/aos/dist/aos.css";


import Parallax, { Layer } from "react-parallax-scroll";
import Footer from "./Footer";
import Partbrands  from "./data/brands";
import ScrollableSection from "./ScrollableSection";
import Metrics from "./Metrics";  
AOS.init({
    once: true
});

const HomeScreen = ({ setScreen, screen }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 800);
    }, []);
  

    const RenderGlobe = () => {
        let totalOrbs = 400;
        const orbs = [];
        for (let x = 0; x < totalOrbs; x++) {
            orbs.push(<div className="c" />);
        }
        return orbs;
    };

    const Home = () => {
        return (
            <div className="section home">
                <div className="inner">
                    <div className="wrap">{RenderGlobe()}</div>
                    {/*
          <div
            className="tiltContainer"
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-delay="500"
          >
            <Layer settings={{ speed: -0.2, type: "translateY" }}>
              <Tilt
                glareEnable={true}
                glareMaxOpacity={"0.03"}
                glareBorderRadius={"50%"}
                glarePosition={"all"}
                trackOnWindow={true}
              >
                <img
                  className="homeIMG"
                  src="https://i.ibb.co/TWfZsGv/New-Project-2022-04-12-T184953-531.png"
                  alt=""
                />
              </Tilt>
            </Layer>
          </div>*/}
                    <div className="innerhome">
                        <div
                            data-aos="fade-down"
                            data-aos-duration="1500"
                            data-aos-delay="500"
                        >
                            <p>
                                Оригинал баталгаатай сэлбэгийг хамгийн <span className="blue">найдвартай</span>  бөгөөд өрсөлдөхүйц үнээр ханган нийлүүлэгч

                            </p>
                        </div>

                        <button
                            data-aos="fade-up"
                            data-aos-duration="1500"
                            data-aos-delay="1000"
                            data-aos-offset="-100"
                            onClick={() => {
                                setScreen("forms");
                                scroll.scrollToTop();
                            }}
                        >
                            Бидэнтэй холбогдох
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const About = () => {
        return (
            <div className="section about" id="about">
                <div className="inner">
                    <div>
                        <div className="flex">
                            <p
                                data-aos="fade-down"
                                data-aos-duration="1000"
                                data-aos-delay="000"
                            >
                                <span className="blue">БИДНИЙ ТУХАЙ</span>

                            </p>
                            <img
                                data-aos="fade"
                                data-aos-duration="15000"
                                data-aos-delay="000"
                                className="lawfirm2"
                                src="https://i.ibb.co/F0Rsdtk/Law-Firm-Law-Office-Lawyer-services-Vector-logo-template-on-transparent-PNG.png"
                                alt=""
                            />
                        </div>
                        <h1
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-delay="000"
                        >
                            “ИТГЭЛ САЙХАН ТЭМҮҮЛЭЛ” ХХК нь 2020 оноос эхлэн дэлхийд чанар, үйл ажиллагаагаараа тэргүүлэгч оригинал авто сэлбэгийн брэндүүдийн бөөний болон жижиглэн худалдааны үйл ажиллагаа болон даалгаврын гүйцэтгэлийн үйл ажиллагааг явуулж байна.
                        </h1>
                        <br></br>
                        <h1
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-delay="000"
                        >
                            Бид таны авто сэлбэг хангамжийн хамгийн найдвартай түнш байх болно.
                        </h1>
                    </div>
                    <div
                        data-aos="fade-left"
                        data-aos-duration="1500"
                        data-aos-delay="500"
                    >
                        <Layer settings={{ speed: -0.1, type: "translateX" }}>
                            <img
                                className="lawfirm"
                                src="https://i.ibb.co/F0Rsdtk/Law-Firm-Law-Office-Lawyer-services-Vector-logo-template-on-transparent-PNG.png"
                                alt=""
                            />
                        </Layer>
                    </div>
                </div>
            </div>
        );
    };

    const Services = () => {
        return (
            <div className="section services" id="services">
                <div className="inner">
                    <div>
                        <h5
                            data-aos="fade-down"
                            data-aos-duration="1500"
                            data-aos-delay="00"
                        >
                            <span className="blue">ЗАХИАЛГА</span>
                        </h5>
                        <h5
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="00"
                        >
                            Бүх төрлийн оригинал сэлбэг эд ангийг бид дараах процессоор та бүхэнд нийлүүлж байна.
                        </h5>
                    </div>
                    <div className="cardsContainer">
                        <div className="leftSection">
                            <div
                                className="container"
                                data-aos="fade-down-right"
                                data-aos-duration="1500"
                                data-aos-delay="00"
                            >
                                <div className="titleWithIMG">
                                    <h2>1. Үнийн санал</h2>
                                    <h3>
                                        Та өөрийн хүссэн барааны жагсаалт болон захиалгын мэдээллээ бидэнд мэдэгдэнэ.
                                    </h3>
                                    <Link
                                        activeClass="active"
                                        to="verify"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={500}
                                    >
                                        {/*
                    <button
                      onClick={() => {
                        setScreen("forms");
                        scroll.scrollToTop();
                      }}
                    >
                      Submit a Project
                    </button>
                    */}
                                    </Link>
                                </div>
                                {/*
                <div className="cardBack">
                  <button>Submit a Project</button>
                </div>
                */}
                            </div>
                            <div
                                className="container"
                                data-aos="fade-down-left"
                                data-aos-duration="1500"
                                data-aos-delay="00"
                            >
                                <div className="titleWithIMG">
                                    <h2>2. Төлбөр</h2>
                                    <h3>
                                        Үнийн саналыг баталгаажуулсан нөхцөлд нэхэмжлэх илгээн төлбөр төлнө.
                                    </h3>
                                    <Link
                                        activeClass="active"
                                        to="verify"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={500}
                                    >
                                        {/*
                    <button
                      onClick={() => {
                        setScreen("forms");
                        scroll.scrollToTop();
                      }}
                    >
                      Submit a Project
                    </button>
                    */}
                                    </Link>
                                </div>
                                {/*
                <div className="cardBack">
                  <button>Submit a Project</button>
                </div>
                */}
                            </div>
                        </div>
                        <div className="rightSection">
                            <div
                                className="container"
                                data-aos="fade-up-right"
                                data-aos-duration="1500"
                                data-aos-delay="00"
                            >
                                <div className="titleWithIMG">
                                    <h2>3. Захиалга</h2>
                                    <h3>
                                        Захиалгын жагсаалтын дагуу тохирсон брэнд үйлдвэрлэгчрүү захиалга хийн татан авалтыг гүйцэтгэнэ.
                                    </h3>
                                    <Link
                                        activeClass="active"
                                        to="verify"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={500}
                                    >
                                        {/*
                    <button
                      onClick={() => {
                        setScreen("forms");
                        scroll.scrollToTop();
                      }}
                    >
                      Submit a Project
                    </button>
                    */}
                                    </Link>
                                </div>
                                {/*
                <div className="cardBack">
                  <button>Submit a Project</button>
                </div>
                */}
                            </div>
                            <div
                                className="container"
                                data-aos="fade-up-left"
                                data-aos-duration="1500"
                                data-aos-delay="00"
                            >
                                <div className="titleWithIMG">
                                    <h2>4. Хүргэлт</h2>
                                    <h3>Таны хүссэн газарт бид гаалийн бүрдүүлэлт нь хийгдсэн чанартай баталгаатай сэлбэгийг хүргэж өгнө. </h3>
                                </div>
                                {/*
                <div className="cardBack">
                  <button>Learn More</button>
                </div>
                */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    


    const Loading = () => {
        if (loading === true) {
            return (
                <div className="loading">
                    <div className="bubble" />
                    <div className="bubble" />
                    <div className="bubble" />
                </div>
            );
        } else {
            return (
                <>
                    <div className="doneLoadingBG" />
                    <div className="doneLoadingLeft" />
                    <div className="doneLoadingRight" />
                </>
            );
        }
    };

    return (
        <div className="App">
            <Parallax>
                {/* <div className="mainSocials">
                    <Socials />
                </div> */}
                <Loading />
                <Home />
                <About />
                <Metrics />
                <Services />
                <ScrollableSection Partbrands={ Partbrands} />
                <Footer />
            </Parallax>
        </div>
    );
};
export default HomeScreen;
