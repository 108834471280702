import { useEffect, useRef } from 'react';

const ScrollableSection = ({ Partbrands }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        let scrollInterval;

        const startScrolling = () => {
            scrollInterval = setInterval(() => {
                container.scrollLeft += 1;
            }, 10); // Adjust speed as necessary
        };

        const stopScrolling = () => {
            clearInterval(scrollInterval);
        };

        // Start scrolling initially
        startScrolling();

        // Stop scrolling on hover
        container.addEventListener('mouseenter', stopScrolling);
        container.addEventListener('mouseleave', startScrolling);

        // Clean up event listeners on component unmount
        return () => {
            clearInterval(scrollInterval);
            container.removeEventListener('mouseenter', stopScrolling);
            container.removeEventListener('mouseleave', startScrolling);
        };
    }, []);

    return (
        <div className="section partners">
            <div className="inner">
                <h1
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    data-aos-delay="000"
                    className='blue'
                >
                    СЭЛБЭГИЙН БРЭНДҮҮД
                </h1>
                <div className="container" >
                    <div className="row" ref={containerRef}>
                        {Partbrands.map((brand, index) => (
                            <div
                                key={index}
                                className="innerContainer"
                                data-aos="fade"
                                data-aos-duration="2000"
                                data-aos-delay="000"
                            >
                                <a href={brand.link} target="_blank" rel="noreferrer">
                                    <img
                                        src={brand.image}
                                        alt="img"
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScrollableSection;
