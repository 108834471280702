import { Link } from "react-scroll";
import { useState } from "react";
import mainLogo from "./assets/logo_hor.png";

const MainDropdown = ({ setScreen, screen }) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <div className="mobileNav">
        <div
          className="inner"
          data-aos="fade"
          data-aos-duration="1500"
          data-aos-delay="00"
        >
          <img
            style={{ height: "40px", alignSelf: "center" }}
            src={mainLogo}
            alt=""
          ></img>
          <div
            className={`buttonWrapper ${dropdown ? "open" : "closed"}`}
            onClick={() => setDropdown(!dropdown)}
          >
            <div className="hamburger2"></div>
          </div>
        </div>
      </div>
      <div className="mainDropdown">
        {dropdown ? (
          <div className="drop">
            <div className="sidebar">
              <div className="innerSidebar">
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <button
                    onClick={() => {
                      setDropdown(false);
                      setScreen("home");
                    }}
                  >
                    Бидний тухай
                  </button>
                </Link>
                <Link
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <button
                    onClick={() => {
                      setDropdown(false);
                      setScreen("home");
                    }}
                  >
                    Захиалга
                  </button>
                </Link>
                <Link
                  activeClass="active"
                  to="verify"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <button
                    onClick={() => {
                      setDropdown(false);
                      setScreen("forms");
                    }}
                  >
                    Бидэнтэй холбогдох
                  </button>
                </Link>
              </div>
              {/* <Socials /> */}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default MainDropdown;
