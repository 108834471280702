// import { animateScroll as scroll } from "react-scroll";
import mainLogo from "./assets/logo_white.png";
const Footer = () => {
  return (
    <div className="section footer">
      <div className="inner">
        <div className="footerInfo">
          <img style={{ width: "250px" }} src={mainLogo} alt=""></img>
          <h3>+965 99505251</h3>
          <h3>info@itgelsaikhan.mn</h3>
          <h3>MN Tower, Улаанбаатар, Монгол</h3>
        </div>
        <div className="footerSend">
          <div className="row">
            <div className="col" id="checkbox">
              <label>Бидэнтэй холбогдох</label>
              <input name="subToNewsLetter" type="text" placeholder="И-мэйл" />
            </div>
          </div>
          <div className="row">
            <div className="col submit">
              <input type="submit" value="Илгээх" />
            </div>
          </div>
        </div>
      </div>
      <h4>Copyright © 2024 Itgel Saikhan</h4>
    </div>
  );
};
export default Footer;
