import { useInView } from "react-hook-inview";

const Metrics = () => {
        const [ref, isVisible] = useInView({
            threshold: 0
        });
        const RenderMetrics = () => {
            return (
                <div className="section metrics">
                    <div className="inner">
                        <div className="container">
                            <div className="flex">
                                <div
                                    className="innerContainer"
                                    data-aos="fade"
                                    data-aos-duration="2000"
                                    data-aos-delay="000"
                                >
                                   
                                        
                                        
                                            <div>
                                                <h2>10YR</h2>
                                            </div>
                                        
                                   
                                    <h4>БИДНИЙ ТУРШЛАГА</h4>
                                </div>
                                <div
                                    className="innerContainer"
                                    data-aos="fade"
                                    data-aos-duration="2000"
                                    data-aos-delay="200"
                                >
                                    <div>
                                                <h2>₮1B</h2>
                                            </div>
                                    <h4>ЗАРСАН СЭЛБЭГ</h4>
                                </div>
                            </div>
                            <div className="flex">
                                <div
                                    className="innerContainer"
                                    data-aos="fade"
                                    data-aos-duration="2000"
                                    data-aos-delay="400"
                                >
                                  <div>
                                                <h2>100k</h2>
                                            </div>
                                    <h4>ХУДАЛДААЛСАН СЭЛБЭГ</h4>
                                </div>
                                <div
                                    className="innerContainer"
                                    data-aos="fade"
                                    data-aos-duration="2000"
                                    data-aos-delay="600"
                                >
                                    <div>
                                                <h2>100%</h2>
                                            </div>
                                    <h4>ХЭРЭГЛЭГЧИЙН СЭТГЭЛ ХАНАМЖ</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        };
        return (
            <div ref={ref}>{isVisible ? <RenderMetrics /> : <RenderMetrics />}</div>
        );
};
    export default Metrics;